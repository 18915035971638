import React from "react"
import styles from "../styles/style.module.css"
import cn from "classnames";

const CustomDropdown = (props) => {
    const { title, setInput, SmokingDuration } = props
    const handleSelect = (e) => {
        setInput(e.target.value)
    }

    return (
        <>
            <div className={cn(styles.inputContainer, props?.className)}>
                <p className={styles.inputLabel}>{title}*</p>
                <div className={styles.dropdown}>
                    <select onChange={handleSelect} aria-label="duration" placeholder="Select an option">
                        <option hidden={true}>{"Select an option"}</option>
                        {SmokingDuration.map((value, index) => {
                            return (
                                <option value={value} key={index}>{value}</option>
                            )
                        })}

                    </select>
                    <span className="focus"></span>
                </div>
            </div>
        </>
    )
}

export default CustomDropdown