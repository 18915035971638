import React, { useEffect, useState } from "react"
import styles from "../styles/style.module.css"
import cn from "classnames";
import Icon from "../assets/filledIcon.svg"
import { Tooltip, removeStyle } from "react-tooltip";

removeStyle()

const CustomInput = (props) => {
    const { title, placeHolder, rangeText, min, max, setInput, toolTipMsg, errorMsg } = props
    const [value, setValue] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (errorMsg) {
            setError(errorMsg)
        }
    }, [errorMsg])

    const handleInput = (e) => {
        const inputVal = Number(e.target.value)
        if (inputVal && inputVal >= min && inputVal <= max) {
            setError('')
            setInput(inputVal)
        } else {
            setError(`Please enter a value between ${min}-${max}`)
        }
    }

    return (
        <>
            <div className={cn(styles.inputContainer, props?.className)}>
                <div className={styles.tooltip}>
                    <p className={styles.inputLabel}>{title}*</p>

                    {toolTipMsg ? <div className="tooltip-container">
                        <img src={Icon} alt="icon" data-tooltip-id="my-tooltip" data-tooltip-html={toolTipMsg} />
                        <Tooltip id="my-tooltip" className="tooltip-blue" />
                    </div> : <></>}
                </div>
                <input type="number" className={styles.input} value={value} placeholder={placeHolder ? placeHolder : "Enter value"}
                    min={min}
                    max={max}
                    step="1"
                    required
                    onChange={(e) => {
                        setError('')
                        setValue(e.target.value === '' ? '' : props?.isInt ? Math.trunc(e.target.value) : e.target.value)
                    }}
                    onBlur={handleInput}
                    style={error.length > 0 ? { borderColor: '#C73C2A' } : { borderColor: 'lightgray' }} />
                {error && error.length > 0 ? <p className={styles.errorText}>{error}</p> : <></>}
                <p className={styles.rangeText}>{rangeText ? rangeText : `Value must be between ${min}-${max}`}</p>
            </div>
        </>
    )
}

export default CustomInput