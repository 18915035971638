import React from "react";
import "../styles/spinner.css";

const Loader = ({ isSmall }) => {
    return (
        <>
            <div className="spinner-container">
                <div className="loading-spinner">
                </div>
            </div>
        </>
    );
}

export default Loader