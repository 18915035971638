import React, { useState } from "react"
import styles from "../styles/style.module.css"
import CustomInput from "./CustomInput"
import CustomButtonOption from "./CustomButtonOption"
import CustomDropdown from "./CustomDropdown"
import Arrow from "../assets/arrow.svg"
import { get } from "../services/Network"
import { GenderOptions, Options, SmokingCategory } from "../constants/constants"
import Loader from "./Loader"

const CalculatorForm = (props) => {
    const { onCalculation, loading } = props
    const range = {
        age: {
            min: 20,
            max: 79
        },
        sysbp: {
            min: 90,
            max: 200
        },
        diabp: {
            min: 60,
            max: 130
        },
        totCol: {
            min: 130,
            max: 320
        },
        hdlCol: {
            min: 20,
            max: 100
        },
        ldlCol: {
            min: 30,
            max: 300
        },
    }
    const [age, setAge] = useState('')
    const [sysBp, setSysBp] = useState('')
    const [diaBp, setDiaBp] = useState('')
    const [totCol, setTotCol] = useState('')
    const [hdlCol, setHdlCol] = useState('')
    const [ldlCol, setLdlCol] = useState('')
    const [gender, setGender] = useState('')
    const [isDiabetic, setDiabetic] = useState('')
    const [hyperTension, setHyperTension] = useState('')
    const [onStatin, setStatin] = useState('')
    const [onAT, setOnAt] = useState('')
    const [isSmoker, setSmoker] = useState('')
    const [quitSmoke, setQuitSmoke] = useState('')
    const [errors, setErrors] = useState({})
    const [quitSmokeOpt, setQuitSmokeOpt] = useState([])
    const [serviceErr, setServiceErr] = useState('')

    const ldlColMsg = `App may not fully represent risk for<br/>patients with LDL-C > 190 mg/dL.<br/>These patients may have familial <br/>hypercholesterolemia and should<br/>be evaluated and considered for<br/>lipid-lowering medication regardless<br/>of the 10 year ASCVD risk.`
    const smokerMsg = `Defined as cigarette smoker based <br/>on patient population studied in<br/>relevant clinical trials. Use clinical <br/>discretion in regards to patients<br/>who use e-cigarettes and other<br/>tobacco products.`
    const statinMsg = `Baseline 10-year ASCVD risk may <br/>be calculated for patients who<br/>have already initiated statin therapy.<br/>It is not necessary to stop and wash<br/>the body clean of statin therapy in<br/>order to re-measure baseline values.<br/>Evidence suggests a patient's<br/> cholesterol levels have the same<br/> impact on ASCVD risk regardless<br/> of whether they were achieved<br/> with aid of statin therapy.`
    const aspirinMsg = `Guidelines do not typically <br/>recommend aspirin therapy for<br/>patients with 10-year risk<10%.<br/> Use USPSTF recommendations<br/>and consider potential risk for<br/>major bleeding when consider<br/>use of aspirin.`

    const validate = () => {
        let errors = {}
        if (age === '') {
            errors.age = `Please enter a value between ${range.age.min}-${range.age.max}`

        }
        if (sysBp === '') {
            errors.sysBp = `Please enter a value between ${range.sysbp.min}-${range.sysbp.max}`

        }
        if (diaBp === '') {
            errors.diaBp = `Please enter a value between ${range.diabp.min}-${range.diabp.max}`
        }
        if (totCol === '') {
            errors.totCol = `Please enter a value between ${range.totCol.min}-${range.totCol.max}`
        }
        if (hdlCol === '') {
            errors.hdlCol = `Please enter a value between ${range.hdlCol.min}-${range.hdlCol.max}`
        }
        if (ldlCol === '') {
            errors.ldlCol = `Please enter a value between ${range.ldlCol.min}-${range.ldlCol.max}`
        }
        if (gender === '') {
            errors.gender = `Please select an option`
        }
        if (isDiabetic === '') {
            errors.isDiabetic = `Please select an option`
        }
        if (isSmoker === '') {
            errors.isSmoker = `Please select an option`
        }
        if (hyperTension === '') {
            errors.hyperTension = `Please select an option`
        }
        return errors
    }

    const getSmokingHistory = async (value) => {
        setSmoker(value)
        if (value === 'Former') {
            try {
                const histRes = await get('/integration/riskEstimator/static-smoking-data')
                if (histRes && histRes.status === 200) {
                    setQuitSmokeOpt(histRes.data)
                }

            } catch (e) {
                const result = e.response?.data
                if (result?.statusCode === 400) {
                    const error = result.errorMessage
                    setServiceErr(Object.values(error))
                } else if (result?.statusCode === 500) {
                    const error = result.message
                    setServiceErr(Object.values(error))
                }
            }


        }
    }

    const onCalculate = async (e) => {
        e.preventDefault();
        const errResult = validate()
        if (Object.keys(errResult).length > 0)
            setErrors(errResult)
        else {
            const params = {
                age,
                gender,
                sysBp,
                diaBp,
                totCol,
                hdlCol,
                ldlCol,
                isDiabetic,
                isSmoker,
                quitSmoke,
                hyperTension,
                onStatin,
                onAT
            }
            onCalculation(params)
        }
    }

    return (
        <form noValidate onSubmit={(e) => e.preventDefault()}>

            <div className={styles.container}>
                <div className={styles.subcontainer}>
                    <CustomInput title={"Current Age"} placeHolder={"Enter age"} rangeText={"Age must be between 20-79"} min={range.age.min} max={range.age.max} setInput={setAge} className={styles.margin_40} errorMsg={errors.age} isInt={true}/>
                    <CustomButtonOption title={"Sex*"} options={GenderOptions} onSelect={setGender} btnErrorMsg={errors.gender} />
                </div>
                <div className={styles.subcontainer}>
                    <CustomInput title={"Systolic Blood Pressure (mm Hg)"} min={range.sysbp.min} max={range.sysbp.max} setInput={setSysBp} className={styles.margin_40} errorMsg={errors.sysBp} />
                    <CustomInput title={"Diastolic Blood Pressure (mm Hg)"} min={range.diabp.min} max={range.diabp.max} setInput={setDiaBp} errorMsg={errors.diaBp} />
                </div>
                <div className={styles.subcontainer}>
                    <CustomInput title={"Total Cholesterol (mg/dL)"} min={range.totCol.min} max={range.totCol.max} setInput={setTotCol} className={styles.margin_40} errorMsg={errors.totCol} />
                    <CustomInput title={"HDL Cholesterol (mg/dL)"} min={range.hdlCol.min} max={range.hdlCol.max} setInput={setHdlCol} className={styles.margin_40} errorMsg={errors.hdlCol} />
                    <CustomInput title={"LDL Cholesterol (mg/dL)"} min={range.ldlCol.min} max={range.ldlCol.max} setInput={setLdlCol} toolTipMsg={ldlColMsg} errorMsg={errors.ldlCol} />
                </div>
                <div className={styles.subcontainer}>
                    <CustomButtonOption title={"History of Diabetes?*"} options={Options} onSelect={setDiabetic}  btnErrorMsg={errors.isDiabetic} />
                    <CustomButtonOption title={"Smoker?*"} options={SmokingCategory} onSelect={getSmokingHistory} toolTipMsg={smokerMsg} btnErrorMsg={errors.isSmoker} />
                    {isSmoker === 'Former' ? <CustomDropdown title={"How long ago did patient quit smoking?"} setInput={setQuitSmoke} SmokingDuration={quitSmokeOpt} /> : <></>}
                </div>
                <div className={styles.subcontainer}>
                    <CustomButtonOption title={"On Hypertension Treatment?*"} options={Options} onSelect={setHyperTension} btnErrorMsg={errors.hyperTension} />
                    <CustomButtonOption title={"On a Statin?"} options={Options} onSelect={setStatin} toolTipMsg={statinMsg} btnErrorMsg={errors.onStatin} />
                    <CustomButtonOption title={"On Aspirin Therapy?"} options={Options} onSelect={setOnAt} toolTipMsg={aspirinMsg} btnErrorMsg={errors.onAT} />
                </div>

            </div>
            <div className={styles.submitSection}>
                <button className={styles.submitButton} onClick={onCalculate} disabled={loading}>{loading ? <><span>{"Loading"}</span><Loader /></> : <><span>{"Calculate Now"}</span><img src={Arrow} alt={"arrow"} className={styles.img1} /></>}</button>
            </div>

        </form>
    )
}

export default CalculatorForm