import React, { useEffect, useState } from "react"
import styles from "../styles/style.module.css"
import Button from "./Button"
import cn from "classnames";
import Icon from "../assets/filledIcon.svg"
import { Tooltip, removeStyle } from "react-tooltip";

removeStyle()

const CustomButtonOption = (props) => {

    const { title, options, isIcon, onSelect, toolTipMsg, btnErrorMsg } = props
    const [active, setActive] = useState()
    const [error, setError] = useState('')

    useEffect(() => {
        if (btnErrorMsg) {
            setError(btnErrorMsg)
        }
    }, [btnErrorMsg])

    return (<>
        <div className={cn(styles.buttonSection, props?.className)}>
            <div className={styles.tooltip}>
                <p className={styles.inputLabel}>{title}</p>
                {toolTipMsg ? <div className="tooltip-container">
                    <img src={Icon} alt="icon" data-tooltip-id="my-tooltip" data-tooltip-html={toolTipMsg} />
                    <Tooltip id="my-tooltip" className="tooltip-blue" />
                </div> : <></>}
            </div>
            <div className={styles.buttonContainer}>
                {options.map((option, index) => {
                    return (
                        <div className={styles.buttonContainer} key={index}>
                            {
                                isIcon ?
                                    <Button className={active === index ? styles.buttonFilled : ''} icon={active === index ? 'selected' : 'unselected'} name={option} onClick={() => {
                                        setError('')
                                        setActive(index)
                                        onSelect(option)
                                    }} />
                                    : <Button className={active === index ? styles.buttonFilled : ''} name={option} onClick={() => {
                                        setError('')
                                        setActive(index)
                                        onSelect(option)
                                    }} />}
                        </div>
                    )
                })}
            </div>
            {error && error.length > 0 ? <p className={styles.errorText}>{error}</p> : <></>}
        </div>
    </>)
}

export default CustomButtonOption