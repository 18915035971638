import React, { useState } from "react"
import Icon from "../assets/logo.svg"
import IconAcc from "../assets/logoAcc.svg"
import Report from "../assets/report.svg"
import styles from "../styles/style.module.css"
import CalculatorForm from "../components/CalculatorForm"
import Notes from "../components/Notes"
import RiskEstimation from "../components/RiskEstimation"
import { post } from "../services/Network"
import cn from "classnames";
const Riskcalculator = () => {

    const [bpMedicine, setBpMedicine] = useState(false)
    const [quitSmoking, setQuitSmoking] = useState(false)
    const [calculate, setCalulate] = useState(false)
    const [risks, setRisks] = useState({})
    const [serviceErr, setServiceErr] = useState('')
    const [medHist, setMedHist] = useState({})
    const [loading, setLoading] = useState(false)
    const [tenYearRisk, setTenYearRisk] = useState('')

    const onCalculation = async (details) => {
        setLoading(true)
        setMedHist(details)
        calculateRisk(details, false)
    }

    const onRecalcute = () => {
        setRisks({})
        setServiceErr('')
        setMedHist('')
        setTenYearRisk('')
        setCalulate(false)
    }

    const calculateRisk = async (details, isReCal) => {
        try {
            const { age, gender, sysBp, diaBp, totCol, hdlCol, ldlCol, isDiabetic, isSmoker, quitSmoke, hyperTension, onStatin, onAT } = details
            const request = {
                "id": risks && risks.id ? risks.id : null,
                "age": age,
                "gender": gender,
                "systolicBloodPressure": sysBp,
                "diastolicBloodPressure": diaBp,
                "totalCholesterol": totCol,
                "hdlCholesterol": hdlCol,
                "ldlCholesterol": ldlCol,
                "historyOfDiabetes": isDiabetic === 'Yes' ? true : false,
                "smokingStatus": isSmoker,
                "formerSmoking": quitSmoke !== '' ? quitSmoke : null,
                "hypertensionTreatmentStatus": hyperTension === 'Yes' ? true : false,
                "statinStatus": onStatin === 'Yes' ? true : false,
                "aspirinTherapyStatus": onAT === 'Yes',
                "quitSmokingTherapy": details?.quitSmokingTherapy,
                "bloodPressureMedicationTherapy": details?.bloodPressureMedicationTherapy

            }
            const result = await post(isReCal ? '/integration/riskEstimator/ascvd-projected-risk-score' : '/integration/riskEstimator/ascvd-risk-score', request)
            if (result.status === 200) {
                const data = result.data
                if (!isReCal) {
                    setRisks(data)
                    setCalulate(true)
                    setTenYearRisk(data.tenYearASCVDRisk)
                    window.scrollTo({ top: 100, left: 0, behavior: 'smooth' });
                } else {
                    setTenYearRisk(data.projectedTenYearRiskScore)
                }
                setServiceErr('')
                setLoading(false)
            }

        } catch (e) {
            const result = e.response?.data
            setLoading(false)
            if (result?.statusCode === 400) {
                const error = result.errorMessage
                setServiceErr(Object.values(error))
            } else if (result?.statusCode === 422) {
                const error = result.violations[0]
                setServiceErr(Object.values(error))
            } else if (result?.statusCode === 500 || result?.statusCode === 401) {
                const error = result.message
                setServiceErr(Object.values(error))
            }
        }
    }

    const onEstimatedReduction = ({ quitSmoking, bpMedicine }) => {
        const changedParam = { ...medHist, bloodPressureMedicationTherapy: bpMedicine, quitSmokingTherapy: quitSmoking }
        setLoading(true)
        calculateRisk(changedParam, true)
    }

    const estReduction = (redParams) => {
        const { quitting, medicine } = redParams
        onEstimatedReduction({ quitSmoking: quitting, bpMedicine: medicine })
    }

    return (<>
        <div>
            <div className={styles.header}>
                <div>
                    <img src={Icon} alt="Lupin Logo" className={styles.headerImg} />
                    <img src={IconAcc} alt="ACC Logo" className={cn(styles.headerImg, styles.img1)} />
                </div>
                <p>{"ASCVD Risk Estimator Plus"}</p>
            </div>
            <div className={styles.subHeaderImg}>
                <p className={styles.subHeader}>{" Lupin Digital Health in collaboration with American College of Cardiology."}</p>
            </div>
            <div className={styles.formHeader}>
                <img src={Report} alt="report" className={styles.img2} />
                <p>{"Risk Estimator should be used for people without any diagnosed cardiac condition"}</p>
            </div>
            <div className={styles.formContainer}>
                <p className={styles.formTitle}>{"Risk Calculator"}</p>
                {serviceErr && serviceErr.length > 0 ? <p className={styles.errorText}>{serviceErr}</p> : <></>}
                {calculate ? <RiskEstimation result={risks} changeToggle={estReduction} reCalculate={onRecalcute} loading={loading} estTenYearRisk={tenYearRisk} /> :
                    <CalculatorForm onCalculation={onCalculation} loading={loading} />
                }
                <Notes />

            </div>
        </div>
    </>)
}

export default Riskcalculator