import React, { useEffect, useState } from "react";
import styles from "../styles/toggle.module.css";
import cn from "classnames"

const ToggleSwitch = ({ label, onClick, value, isDisable }) => {
    const [toggle, setToggle] = useState(value)

    useEffect(() => {
        if (value !== undefined)
            setToggle(value)
    }, [value])

    const handleClick = (e) => {
        setToggle(!toggle)
        onClick(!toggle)
    }
    return (
        <div className={styles.toggleContainer}>
            <div className={styles.toggleSwitch}>
                <input type="checkbox" className={styles.checkbox}
                    name={label} id={label} onChange={handleClick} value={toggle} checked={toggle} disabled={isDisable} />
                <label className={styles.label} htmlFor={label}>
                    <span className={cn(styles.inner, isDisable ? styles.disableToggle : '')} />
                    <span className={styles.switch} />
                </label>
            </div>
            <p style={{ width: '75%' }}>{label}</p>
        </div>
    );
};

export default ToggleSwitch;