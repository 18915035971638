// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 15px;
    height: 15px;
    border: 2px solid #f3f3f3;
    /* Light grey */
    border-top: 2px solid #383636;
    /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/spinner.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,6BAA6B;IAC7B,SAAS;IACT,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["@keyframes spinner {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.loading-spinner {\n    width: 15px;\n    height: 15px;\n    border: 2px solid #f3f3f3;\n    /* Light grey */\n    border-top: 2px solid #383636;\n    /* Blue */\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n}\n\n.spinner-container {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 20px;\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
