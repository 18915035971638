import React from "react"
import styles from "../styles/style.module.css"
import cn from "classnames";
const Notes = () => {
    return (<>
        <div className={styles.notesSection}>
            <div className={styles.notesContent}>
                <p>{"**10-year risk for ASCVD is categorized as:"}</p>
                <p>{"Low-risk (<5%)"}</p>
                <p>{"Borderline risk (5% to 7.4%)"}</p>
                <p>{"Intermediate risk (7.5% to 19.9%)"}</p>
                <p>{"High risk (≥20%)"}</p>
                <p className={cn(styles.notesSecondSection, styles.notesContent)}>{"*Indicates a field required to calculate current 10-year ASCVD risk for patients age 40-79 or Lifetime risk for patients age 20-59. Risk will automatically calculate once these fields are populated."}</p>
            </div>
        </div>
    </>)
}

export default Notes