export const SmokingDuration = [
    "Unknown",
    "Less than 6 month ago",
    "6 month - 1.5 years ago",
    "1.5 - 2.5 years ago",
    "2.5 - 3.5 years ago",
    "3.5 - 5 years ago",
    "More than 5 years ago"
]

export const GenderOptions = ['Male', 'Female', 'Other']

export const SmokingCategory = ['Current', 'Former', 'Never']

export const Options = ['Yes', 'No']

export const RISK_MSG = 'This calculator only provides 10-year risk estimates for individuals 40-79 years of age.'

