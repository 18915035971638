import axios from "axios";
const { REACT_APP_BASE_URL, REACT_APP_ENV_ID, REACT_APP_KEY } = process.env;

const apiClient = axios.create({
    baseURL: REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS",
        "clientId": "ldh",
        "Authorization": REACT_APP_KEY,
    }
});

export const post = (url, request) => {
    try {
        const response = apiClient.post(url, request)
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}

export const get = (url) => {
    try {
        const response = apiClient.get(url)
        return response
    } catch (e) {
        console.log(e)
    }
}

export const patch = (url, request) => {
    try {
        const response = apiClient.patch(url, request)
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}