import React from "react"
import styles from "../styles/style.module.css"
import Icon from "../assets/unfilledIcon.svg"
import WhiteIcon from "../assets/whiteIcon.svg"
import cn from "classnames";
const Button = (props) => {
    const { name, icon, onClick} = props

    return (<>
        <>
            <button className={cn(styles.button, props?.className)} onClick={() => onClick(name)}>{name}
                {icon ? <img src={icon === 'selected' ? WhiteIcon : Icon} className={styles.buttonIcon} alt="info" /> : <></>}
            </button>
        </>
    </>)
}

export default Button