// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: whitesmoke;
  overflow-y: auto;
}

body p {
  margin: 3px;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
  background: transparent !important;

}

input[type=number]:focus {
  border: 1px solid darkgray;
}

.tooltip-container .tooltip-blue {
  background-color: #2F3273;
  color: white;
  border-radius: 6px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 6px 10px 6px 12px;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  cursor: inherit;
  font-weight: 400;
  /* background-image: url('../src/assets/downArrow.svg'); */
  grid-area: select;
}

option {
  padding: 6px 10px 6px 12px;
}


select:focus+.focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,kCAAkC;;AAEpC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,YAAY;EACZ,0BAA0B;EAC1B,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,0DAA0D;EAC1D,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,qCAAqC;EACrC,sBAAsB;AACxB","sourcesContent":[".App {\n  background-color: whitesmoke;\n  overflow-y: auto;\n}\n\nbody p {\n  margin: 3px;\n}\n\ninput[type=number]::-webkit-inner-spin-button {\n  opacity: 1;\n  background: transparent !important;\n\n}\n\ninput[type=number]:focus {\n  border: 1px solid darkgray;\n}\n\n.tooltip-container .tooltip-blue {\n  background-color: #2F3273;\n  color: white;\n  border-radius: 6px;\n}\n\nselect {\n  appearance: none;\n  background-color: transparent;\n  border: none;\n  padding: 6px 10px 6px 12px;\n  margin: 0;\n  width: 100%;\n  font-family: inherit;\n  font-size: 16px;\n  cursor: inherit;\n  font-weight: 400;\n  /* background-image: url('../src/assets/downArrow.svg'); */\n  grid-area: select;\n}\n\noption {\n  padding: 6px 10px 6px 12px;\n}\n\n\nselect:focus+.focus {\n  position: absolute;\n  top: -1px;\n  left: -1px;\n  right: -1px;\n  bottom: -1px;\n  border: 2px solid var(--select-focus);\n  border-radius: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
