import './App.css';
import Riskcalculator from './pages/Riskcalculator';

function App() {
  return (
    <div className="App">
      <Riskcalculator />
    </div>
  );
}

export default App;
