import React, { useEffect, useState } from "react"
import styles from "../styles/style.module.css"
import ForwardArrow from "../assets/forwardArrow.svg"
import cn from "classnames";
import Arrow from "../assets/arrow.svg"
import ToggleSwitch from "./ToggleSwitch"
import { patch } from "../services/Network";
import Loader from "./Loader";
import { RISK_MSG } from "../constants/constants";


const RiskEstimation = (props) => {
    const { result, changeToggle, reCalculate, loading, estTenYearRisk } = props
    const [bpMed, setBpMed] = useState(false)
    const [quitSmoke, setQuitSmoke] = useState(false)
    const [phoneNo, setPhoneNo] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [isDisable, setDisable] = useState(false)
    const [riskMsg, setRiskMsg] = useState('')
    const [disableQuitSmoking, setDisableQuitSmoking] = useState(false)
    const [disableBPTherapy, setDisableBPTherapy] = useState(false)

    useEffect(() => {
        if (result && result.therapyFlags) {
            const { enableQuitSmoking, enableAddBloodPressureMedication } = result.therapyFlags
            setDisableQuitSmoking(!enableQuitSmoking)
            setDisableBPTherapy(!enableAddBloodPressureMedication)
        }

    }, [result]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading && estTenYearRisk) {
            if (!estTenYearRisk.includes('%')) setRiskMsg(estTenYearRisk)
            else if (quitSmoke && bpMed) setRiskMsg(`${estTenYearRisk} with smoking cessation and BP medication`)
            else if (quitSmoke) setRiskMsg(`${estTenYearRisk} with smoking cessation`)
            else if (bpMed) setRiskMsg(`${estTenYearRisk} with BP medication`)
            else setRiskMsg(`${estTenYearRisk} with no treatments selected yet`)
        } else if (estTenYearRisk === ''){
            setRiskMsg(!result.tenYearASCVDRisk.includes('%') ? result.tenYearASCVDRisk : `${result.tenYearASCVDRisk} with no treatments selected yet`)
        }

    }, [quitSmoke, bpMed, estTenYearRisk, loading])

    const handleInput = (e) => {
        const phone = e.target.value
        setSuccess('')
        if ((phone && phone.match(/^[0-9+]+$/)) || phone === "") {
            setError('')
            setPhoneNo(phone)
        }
    }

    const onSubmit = async () => {
        setSuccess('')
        if (phoneNo === "" || !(/^([+]\d{2})?\d{10}$/).test(phoneNo)) {
            setError(`Please enter the valid Phone number`)
        } else {
            try {
                const request = {
                    "id": result && result.id,
                    "operation": "update",
                    "resource": "phone",
                    "value": phoneNo
                }
                const res = await patch('/integration/riskEstimator/ascvd-risk-score', request)
                if (res.status === 200) {
                    setSuccess('Your Phone number has been submitted')
                }

            } catch (e) {
                const result = e.response?.data
                if (result?.statusCode === 400) {
                    const error = result.errorMessage
                    setError(Object.values(error))
                } else if (result?.statusCode === 500) {
                    const error = result.message
                    setError(Object.values(error))
                }
            }

        }
    }

    const RiskType = ({ label, value }) => (
        <div className={styles.riskSection}>
            <div className={styles.dot} />
            <p className={styles.riskLabel}>{label}</p>
            <img src={ForwardArrow} alt="Forward Arrow" className={styles.arrow} />
            <p className={styles.riskValue}>{value}</p>
        </div>
    )

    return (
        <>

            <div className={styles.container}>
                <div className={cn(styles.subcontainer, styles.riskContainer)}>
                    <RiskType label={"Current 10-year ASCVD Risk"} value={result.tenYearASCVDRisk} />
                    <RiskType label={"Lifetime ASCVD Risk"} value={result.lifeTimeASCVDRisk} />
                    <RiskType label={"Optimal ASCVD Risk"} value={result.optimalASCVDRisk} />
                </div>
                <div className={cn(styles.submitSection, styles.riskButton)}>
                    <button className={styles.submitButton} onClick={reCalculate} disabled={loading}>{loading ? <><span>{"Loading"}</span><Loader /></> : <><span>{"Re-Calculate"}</span><img src={Arrow} alt={"arrow"} className={styles.img1} /></>}</button>
                </div>
            </div>
            <div className={cn(styles.riskLabel, styles.submitSection, styles.phoneLabel)}>
                <p className={styles.phoneText}>{"Get assistance on how to manage your risk efficiently"}</p>
                <div>
                    <input type="tel" className={styles.phoneInput} placeholder={"Enter phone number"} value={phoneNo} onChange={handleInput} />
                    {error && error.length > 0 ? <p className={cn(styles.errorText, styles.errorPh)}>{error}</p> : <></>}
                    {success && success.length > 0 ? <p className={cn(styles.errorText, styles.errorPh, styles.successMsg)}>{success}</p> : <></>}
                </div>
                <button className={styles.submitButton} onClick={onSubmit}>{"Submit"}</button>
            </div>
            <p className={styles.prrLabel}>{"Estimate reduction of risk by changes in your therapy"}</p>
            <div className={cn(styles.container, styles.prrContainer)}>
                <div className={styles.blueSection}>
                    <p>{"Projected 10-Year ASCVD Risk"}</p>
                    <p className={styles.blueContent}>{riskMsg}</p>
                </div>
                <div className={styles.prrContent}>
                    <div className={styles.toggleSection}>
                        <ToggleSwitch label="Quit Smoking" onClick={(value) => {
                            setQuitSmoke(value)
                            changeToggle({ quitting: value, medicine: bpMed })
                        }} value={quitSmoke} isDisable={disableQuitSmoking} />
                        <ToggleSwitch label="Start/Add Blood Pressure Medications(s)" onClick={(value) => {
                            setBpMed(value)
                            changeToggle({ quitting: quitSmoke, medicine: value })
                        }} value={bpMed} isDisable={disableBPTherapy} />
                    </div>
                    <div style={{ width: "95%" }}>
                        <p>{"*Guidelines do not recommend statin therapy for patients with 10-year risk < 5%"}</p>
                        <p>{"*Guidelines do not typically recommend aspirin therapy for patients with 10-year risk < 10%"}</p>
                        <p>{"*ACC/AHA Guidelines do not specify antihypertensive drug therapy for SBP<120 mmHg (<130 mmHg w/diabetes)"}</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RiskEstimation